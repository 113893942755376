<template>
  <div class="hold-transition">
    <loading v-if="cargando || loading" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">
                  Editar Nominación No. <b>{{ this.$route.params.id }}</b>
                </h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item">
                    <router-link to="/Admin">Hidrocarburos</router-link>
                  </li>
                  <li class="breadcrumb-item active">Nominaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="row">
              <div class="col-md-8">
                <form @submit="saveNominacion" onsubmit="return false">
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="form-group">
                            <label>Fecha inicial</label>
                            <input
                              type="date"
                              :disabled="estado == 2"
                              class="form-control form-control-sm"
                              v-model="fecha_inicial"
                              :class="
                                $v.fecha_inicial.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label>Fecha final</label>
                            <input
                              type="date"
                              class="form-control form-control-sm"
                              :disabled="estado == 2"
                              v-model="fecha_final"
                              :class="
                                $v.fecha_final.$invalid
                                  ? 'is-invalid'
                                  : 'is-valid'
                              "
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label>Nombre</label>
                            <input
                              type="text"
                              :disabled="estado == 2"
                              v-model="nombre"
                              class="form-control form-control-sm"
                              :class="
                                $v.nombre.$invalid ? 'is-invalid' : 'is-valid'
                              "
                              required
                            />
                          </div>
                        </div>
                        <div class="col-md-5">
                          <div class="form-group">
                            <label for="">Planeaciones</label>
                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              :disabled="estado == 2"
                              v-model="planeacion"
                              label="nombre"
                              :options="planeacionesOption"
                              placeholder="Planeacion"
                            >
                              <template #search="{ attributes, events }">
                                <input
                                  :disabled="estado == 2"
                                  class="vs__search"
                                  :required="!planeacion"
                                  v-bind="attributes"
                                  v-on="events"
                                />
                              </template>
                            </v-select>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group">
                            <label for="">Calificacion</label>

                            <v-select
                              :class="
                                $store.getters.getDarkMode ? 'dark-vselect' : ''
                              "
                              v-model="calificacion"
                              :disabled="estado == 2"
                              label="nombre"
                              :options="calificacionesOption"
                              placeholder="Calificacion"
                            >
                              <template #search="{ attributes, events }">
                                <input
                                  :disabled="estado == 2"
                                  class="vs__search"
                                  :required="!calificacion"
                                  v-bind="attributes"
                                  v-on="events"
                                />
                              </template>
                            </v-select>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label>Estado</label>
                            <select
                              :disabled="estado == 2"
                              v-model="estado"
                              @change="closeNominacion"
                              class="form-control form-control-sm"
                              required
                            >
                              <option
                                v-for="r in listas"
                                v-bind:key="r.id"
                                v-bind:value="r.numeracion"
                              >
                                {{ r.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group">
                            <label for="">Tipos Productos</label>
                            <select
                              :disabled="estado == 2"
                              v-model="tipo_producto"
                              class="form-control form-control-sm"
                              required
                            >
                              <option
                                v-for="r in tipos_productoOption"
                                v-bind:key="r.id"
                                v-bind:value="r.numeracion"
                              >
                                {{ r.descripcion }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <label>Observación</label>
                            <textarea
                              :disabled="estado == 2"
                              v-model="observacion"
                              class="form-control form-control-sm"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="card-footer">
                      <div class="row">
                        <router-link to="/Hidrocarburos/Nominaciones">
                          <button
                            type="button"
                            class="btn btn-secondary"
                            @click="newItem = true"
                            v-if="
                              $store.getters.can(
                                'hidrocarburos.nominaciones.index'
                              )
                            "
                          >
                            <i class="fas fa-reply"></i><br />Volver
                          </button>
                        </router-link>
                      </div>
                    </div>
                  </div>
                </form>
              </div>

              <div class="col-md-4">
                <div class="card">
                  <div class="card-header">
                    <div class="row">
                      <div class="col-md-6">
                        <h4 class="card-title mt-2">Archivos</h4>
                      </div>
                      <div class="col-md-6">
                        <button
                          class="btn btn-info btn-sm float-right"
                          type="button"
                          v-on:click="downloadFile"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.nominaciones.export'
                            )
                          "
                        >
                          <i class="fa fa-download" aria-hidden="true"></i>
                          Plantilla
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <input
                      :disabled="estado == 2"
                      type="file"
                      class="form-control"
                      id="fileDocu"
                      @change="fileUpload"
                      v-if="
                        $store.getters.can(
                          'hidrocarburos.nominaciones.import'
                        ) &&
                          $store.getters.can('hidrocarburos.nominaciones.edit')
                      "
                    />
                    <hr />
                    <textarea
                      :disabled="estado == 2"
                      v-model="logFile"
                      readonly
                      class="form-control"
                      placeholder="Log de carga del archivo..."
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="card card-outline card-info collapsed-card"
              v-if="
                $store.getters.can('hidrocarburos.nominaciones.showGeneral')
              "
            >
              <div class="card-header">
                <h5 class="card-title">General</h5>
                <div class="card-tools">
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body">
                <button
                  class="btn btn-info"
                  @click="calcTotalesGeneral()"
                  v-if="
                    $store.getters.can(
                      'hidrocarburos.nominaciones.addDetail'
                    ) && $store.getters.can('hidrocarburos.nominaciones.edit')
                  "
                >
                  Calcular
                  <i class="fas fa-check-circle"></i>
                </button>
                <div class="row">
                  <div class="col-md-12">
                    <table
                      class="
                        table table-sm table-striped table-hover table-bodered
                      "
                    >
                      <thead>
                        <tr>
                          <th>
                            <button
                              disabled="disabled"
                              class="btn btn-xs bg-light"
                              style="font-weight: bold"
                            >
                              Empresa
                            </button>
                          </th>
                          <th>
                            <button
                              disabled="disabled"
                              class="btn btn-xs bg-light"
                              style="font-weight: bold"
                            >
                              Calificacion
                            </button>
                          </th>
                          <th>
                            <button
                              disabled="disabled"
                              class="btn btn-xs bg-light"
                              style="font-weight: bold"
                            >
                              Volumen
                            </button>
                          </th>
                          <th>
                            <button
                              v-b-popover.hover.top="'Carrotanques'"
                              title="Medida"
                              class="btn btn-xs bg-light"
                            >
                              Cargues Mes
                            </button>
                          </th>
                          <th>
                            <button
                              v-b-popover.hover.top="'Carrotanques'"
                              title="Medida"
                              class="btn btn-xs bg-light"
                              style="font-weight: bold"
                            >
                              Cargues Dia
                            </button>
                          </th>
                          <th>
                            <button
                              disabled="disabled"
                              class="btn btn-xs bg-light"
                              style="font-weight: bold"
                            >
                              Email
                            </button>
                          </th>
                          <th>
                            <button
                              disabled="disabled"
                              class="btn btn-xs bg-light"
                              style="font-weight: bold"
                            >
                              Accion
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="tots in carguesTotalesEmpresas"
                          :key="tots.id"
                          :class="{
                            editing: tots == editedItem,
                          }"
                          v-cloak
                        >
                          <td>{{ tots.empresa.razon_social }}</td>
                          <td>{{ tots.calificacion }}</td>
                          <td>{{ tots.tot_volumen }}</td>
                          <td>{{ tots.tot_cargues }}</td>
                          <td>
                            <div class="view">
                              {{ tots.tot_cargues_dia }}
                            </div>
                            <div class="edit">
                              <input
                                :disabled="estado == 2"
                                type="number"
                                v-model="tots.tot_cargues_dia"
                                required
                              />
                            </div>
                          </td>
                          <td>
                            <div class="be-check">
                              <button
                                v-if="
                                  estado == 2 &&
                                    $store.getters.can(
                                      'hidrocarburos.nominaciones.edit'
                                    )
                                "
                                type="button"
                                class="btn btn-sm bg-olive"
                                @click="correoEmpresa(tots)"
                                :id="'checkbox_' + tots.id"
                              >
                                <i class="fas fa-check"></i>
                              </button>
                            </div>
                          </td>
                          <td style="width: 50px">
                            <div class="btn-group float-right">
                              <div class="view">
                                <button
                                  :disabled="estado == 2"
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.nominaciones.edit'
                                    )
                                  "
                                  type="button"
                                  class="btn btn-sm bg-navy"
                                  @click="editData(tots)"
                                >
                                  <i class="fas fa-edit"></i>
                                </button>
                              </div>
                              <div class="edit">
                                <button
                                  :disabled="estado == 2"
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.nominaciones.edit'
                                    )
                                  "
                                  type="button"
                                  class="btn btn-sm bg-navy"
                                  @click="saveEditData(tots)"
                                >
                                  <i class="fas fa-save"></i>
                                </button>
                              </div>
                              <div class="edit">
                                <button
                                  class="btn btn-sm btn-danger"
                                  :disabled="estado == 2"
                                  v-if="
                                    $store.getters.can(
                                      'hidrocarburos.nominaciones.edit'
                                    )
                                  "
                                  @click="editedItem = null"
                                >
                                  <i class="fa fa-times"></i>
                                </button>
                              </div>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <button
                class="btn btn-info"
                @click="enviarCorreo()"
                v-if="
                  $store.getters.can(
                    'hidrocarburos.nominaciones.enviarCorreo'
                  ) &&
                    $store.getters.can('hidrocarburos.nominaciones.edit') &&
                    estado == 2
                "
              >
                Enviar correo General
                <i class="fas fa-check-circle"></i>
              </button>
            </div>

            <div
              class="card card-outline card-success collapsed-card"
              v-if="
                $store.getters.can('hidrocarburos.nominaciones.showBloques')
              "
            >
              <div class="card-header">
                <h5 class="card-title">Bloques - Sitios</h5>
                <div class="card-tools">
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body" style="display: none">
                <!-- SECCION PARA CONSTRUCCION DEL ARBOL BLOQUE->SITIO -->
                <div class="row" align="center" v-show="flagArbol == false">
                  <div class="col-4">
                    <div class="form-group">
                      <label for="">Buscar</label>
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        v-model="filter"
                        placeholder="Digite su busqueda"
                      />
                    </div>
                  </div>
                </div>
                <div class="row" v-show="flagArbol == false">
                  <div class="col-12">
                    <ul
                      class="
                        nav nav-pills nav-sidebar
                        flex-column
                        nav-flat nav-child-indent
                      "
                      data-widget="treeview"
                      role="menu"
                      data-accordion="false"
                    >
                      <li
                        class="nav-item has-treeview"
                        v-for="(base, index) in filteredRows"
                        :key="base.id"
                      >
                        <a href="#" class="nav-link">
                          <i class="nav-icon fas fa-circle"></i>
                          <p v-html="highlightMatches(base.nombre)">
                            <i class="right fas fa-angle-left"></i>
                          </p>
                          <p>
                            | Vol. Mes: {{ volumenMes[index] }} | Vol. Dia:
                            {{ volumenDia[index] }} | Cargues Mes:
                            {{ carguesMes[index] }} | Cargues Dia:
                            {{ carguesDia[index] }}
                          </p>
                        </a>
                        <ul class="nav nav-treeview">
                          <li
                            class="nav-item has-treeview"
                            v-for="sitio in base.sitios"
                            :key="sitio.id"
                          >
                            <a class="nav-link">
                              <div v-if="tipo_producto == 1">
                                <div v-if="sitio.promedio_cargue == 0">
                                  <i class="nav-icon fas fa-circle"></i>

                                  <p>
                                    Nombre: {{ sitio.nombre }} | Promedio
                                    Cargue: 200.00 (por defecto)

                                    <i class="right fas fa-angle-left"></i>
                                  </p>
                                </div>
                                <div v-else>
                                  <i class="nav-icon fas fa-circle"></i>
                                  <p>
                                    Nombre: {{ sitio.nombre }} | Promedio
                                    Cargue:

                                    {{ sitio.promedio_cargue }}
                                    <i class="right fas fa-angle-left"></i>
                                  </p>
                                </div>
                              </div>
                              <div v-else>
                                <div v-if="sitio.prom_cargue_planta == 0">
                                  <i class="nav-icon fas fa-circle"></i>

                                  <p>
                                    Nombre: {{ sitio.nombre }} | Promedio
                                    Cargue: 200.00 (por defecto)

                                    <i class="right fas fa-angle-left"></i>
                                  </p>
                                </div>
                                <div v-else>
                                  <i class="nav-icon fas fa-circle"></i>
                                  <p>
                                    Nombre: {{ sitio.nombre }} | Promedio
                                    Cargue:

                                    {{ sitio.prom_cargue_planta }}
                                    <i class="right fas fa-angle-left"></i>
                                  </p>
                                </div>
                              </div>
                            </a>
                            <form
                              @submit="guardaVolumenes(sitio)"
                              onsubmit="return false"
                            >
                              <div class="row mb-2">
                                <div class="col-1"></div>
                                <div class="col-2">
                                  <label
                                    class="btn btn-xs bg-light"
                                    disabled="disabled"
                                  >
                                    Vol. Mes
                                  </label>
                                  <input
                                    :disabled="estado == 2"
                                    :id="'volumenes-mes-' + sitio.id"
                                    v-model="volumenes['mes-' + sitio.id]"
                                    class="form-control form-control-sm"
                                    type="number"
                                    placeholder="Mes"
                                    step="0.01"
                                    size="3"
                                    required
                                    @keyup="
                                      calcVolumenes(
                                        1,
                                        sitio.id,
                                        tipo_producto == 1
                                          ? sitio.promedio_cargue
                                          : sitio.prom_cargue_planta
                                      )
                                    "
                                  />
                                </div>

                                <div class="col-2">
                                  <label
                                    class="btn btn-xs bg-light"
                                    disabled="disabled"
                                  >
                                    Vol. dia
                                  </label>
                                  <input
                                    :disabled="estado == 2"
                                    v-model="volumenes['dia-' + sitio.id]"
                                    :id="'volumenes-dia-' + sitio.id"
                                    class="form-control form-control-sm"
                                    type="number"
                                    placeholder="Día"
                                    step="0.01"
                                    size="3"
                                    required
                                    @keyup="
                                      calcVolumenes(
                                        2,
                                        sitio.id,
                                        tipo_producto == 1
                                          ? sitio.promedio_cargue
                                          : sitio.prom_cargue_planta
                                      )
                                    "
                                  />
                                </div>
                                <div class="col-2">
                                  <label
                                    v-b-popover.hover.top="'Carrotanques'"
                                    title="Medida"
                                    class="btn btn-xs bg-light"
                                  >
                                    Cargues Mes
                                  </label>
                                  <input
                                    :disabled="estado == 2"
                                    v-model="cargues['mes-' + sitio.id]"
                                    :id="'cargues-mes-' + sitio.id"
                                    class="form-control form-control-sm"
                                    type="number"
                                    placeholder="Mes"
                                    step="0.01"
                                    size="3"
                                    required
                                    @keyup="
                                      calcVolumenes(
                                        3,
                                        sitio.id,
                                        sitio.promedio_cargue
                                      )
                                    "
                                  />
                                </div>
                                <div class="col-2">
                                  <label
                                    v-b-popover.hover.top="'Carrotanques'"
                                    title="Medida"
                                    class="btn btn-xs bg-light"
                                  >
                                    Cargues dia
                                  </label>
                                  <input
                                    :disabled="estado == 2"
                                    v-model="cargues['dia-' + sitio.id]"
                                    class="form-control form-control-sm"
                                    :id="'cargues-dia-' + sitio.id"
                                    type="number"
                                    placeholder="Día"
                                    step="0.01"
                                    size="3"
                                    required
                                    @keyup="
                                      calcVolumenes(
                                        4,
                                        sitio.id,
                                        tipo_producto == 1
                                          ? sitio.promedio_cargue
                                          : sitio.prom_cargue_planta
                                      )
                                    "
                                  />
                                </div>
                                <div class="col-2 mt-3" v-if="!loading">
                                  <!-- v-on:click="guardaPermisos(nivel3.id)" data-toggle="modal" data-target="#modal-form-items" -->
                                  <button
                                    :disabled="estado == 2"
                                    type="submit"
                                    class="btn btn-sm bg-navy"
                                    data-toggle="modal"
                                    data-target="#modal-form"
                                    v-if="
                                      $store.getters.can(
                                        'hidrocarburos.nominaciones.assignCompany'
                                      ) &&
                                        $store.getters.can(
                                          'hidrocarburos.nominaciones.edit'
                                        )
                                    "
                                  >
                                    Agregar
                                  </button>
                                </div>
                                <div class="col-2 mt-3" v-else-if="loading">
                                  <div
                                    class="spinner-border text-dark"
                                    role="status"
                                  >
                                    <span class="sr-only">Guardando...</span>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card card-outline card-success collapsed-card"
              v-if="
                $store.getters.can('hidrocarburos.nominaciones.showResumen')
              "
            >
              <div class="card-header">
                <h5 class="card-title">Resumen</h5>
                <div class="card-tools">
                  <button
                    type="button"
                    class="btn btn-tool"
                    data-card-widget="collapse"
                    data-toggle="tooltip"
                    title="Collapse"
                    @click="getDetalles()"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </div>
              </div>
              <div class="card-body" style="display: none">
                <input
                  type="text"
                  class="form-control"
                  v-model="buscarDet"
                  placeholder="Buscar"
                />
                <table class="table table-sm table-striped table-hover">
                  <thead>
                    <tr>
                      <th rowspan="2">Sitio</th>
                      <th rowspan="2">Promedio Cargue</th>
                      <th rowspan="2">Empresa</th>
                      <th rowspan="2">Calificacion</th>
                      <th rowspan="2">Ajuste Acum.</th>
                      <th colspan="2">Cargues</th>
                      <th colspan="2">Volumen</th>
                      <th colspan="2">Adicionales</th>
                      <th colspan="2">Disminucion</th>
                    </tr>
                    <tr>
                      <th>Mes</th>
                      <th>Dia</th>
                      <th>Mes</th>
                      <th>Dia</th>
                      <th>Mes</th>
                      <th>Dia</th>
                      <th>Mes</th>
                      <th>Dia</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="det in filteredRowsDetail" :key="det.id">
                      <td>{{ det.det_nominacion.sitio.nombre }}</td>
                      <td>
                        {{
                          tipo_producto == 1
                            ? det.det_nominacion.sitio.promedio_cargue
                            : det.det_nominacion.sitio.prom_cargue_planta
                        }}
                      </td>
                      <td>{{ det.empresa.razon_social }}</td>
                      <td>{{ det.calificacion }}</td>
                      <td>{{ det.ajuste_acum }}</td>
                      <td>{{ det.cargues_mes }}</td>
                      <td>{{ det.cargues_dia }}</td>
                      <td>{{ det.volumen_mes }}</td>
                      <td>{{ det.volumen_dia }}</td>
                      <td>{{ det.adicional_mes }}</td>
                      <td>{{ det.adicional_dia }}</td>
                      <td>{{ det.disminucion_mes }}</td>
                      <td>{{ det.disminucion_dia }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div
      class="modal fade"
      id="modal-form"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-xl" style="max-width: 90% !important">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left p-2">
            <h5 class="modal-title text-white">
              Nombre: {{ nomSitioActual }} | Promedio Cargue:
              {{ promedioCargueActual }}
            </h5>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-7">
                <div class="form-group">
                  <label for="descripcion">Empresa</label>
                  <v-select
                    :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                    v-model="empresa"
                    multiple
                    label="razon_social"
                    :options="arrListasForm.empresas"
                    placeholder="Empresa"
                    @input="addEmpresa('one')"
                    v-if="$store.getters.can('hidrocarburos.nominaciones.edit')"
                  ></v-select>
                </div>
              </div>
              <div class="col-md-1">
                <button
                  :disabled="estado == 2"
                  type="button"
                  class="btn btn-dark mt-4"
                  @click="addEmpresa('all')"
                  v-if="$store.getters.can('hidrocarburos.nominaciones.edit')"
                >
                  Todo
                </button>
              </div>
              <div class="col-md-2">
                <button
                  :disabled="estado == 2"
                  type="button"
                  class="btn btn-info mt-4"
                  @click="asignarVolumenes()"
                  v-if="$store.getters.can('hidrocarburos.nominaciones.edit')"
                >
                  Generar volumenes
                </button>
              </div>
              <div class="col-md-2"></div>
            </div>
            <div class="row">
              <div class="col-12">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                  style="text-font: 11px"
                >
                  <thead class="text-center table-info">
                    <tr>
                      <th rowspan="2">Empresa</th>
                      <th rowspan="2">Calificacion</th>
                      <th colspan="2">Volumen</th>
                      <th colspan="2">Cargues</th>
                      <th colspan="2">Adicional</th>
                      <th colspan="3">Disminución</th>
                      <th rowspan="2" colspan="2">
                        Opciones
                        <button
                          :disabled="estado == 2"
                          type="button"
                          class="btn btn-success btn-sm"
                          @click="guardadoGeneral()"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.nominaciones.edit'
                            )
                          "
                        >
                          <i class="fas fa-save"></i>
                        </button>
                      </th>
                    </tr>
                    <tr>
                      <th>Mes</th>
                      <th>Día</th>
                      <th>Mes</th>
                      <th>Día</th>
                      <th>Mes</th>
                      <th>
                        Día
                        <input
                          :disabled="estado == 2"
                          type="number"
                          class="form-control form-control-sm"
                          v-model="adicionalDiaModal"
                          @blur="adicionalDiaModal = null"
                          @input="cambiarAdicionalesDia()"
                        />
                      </th>

                      <th>Mes</th>
                      <th>
                        Día
                        <input
                          :disabled="estado == 2"
                          type="number"
                          class="form-control form-control-sm"
                          v-model="descuentoDiaModal"
                          @blur="descuentoDiaModal = null"
                          @input="cambiarDescuentosDia()"
                        />
                      </th>
                      <th>NO Dism</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(row, index) in empresaSelected[
                        'sitio-' + idSitioActual
                      ]"
                      :key="index"
                    >
                      <td>
                        {{ row.razon_social }}
                      </td>
                      <td>
                        {{
                          empresasData[
                            idSitioActual + "-calificacion-" + row.id
                          ]
                        }}
                      </td>

                      <td>
                        <input
                          :disabled="estado == 2"
                          :id="'c' + idSitioActual + '-volMesE-' + row.id"
                          type="number"
                          v-model="
                            empresasData[idSitioActual + '-volMesE-' + row.id]
                          "
                          class="form-control form-control-sm"
                          step="any"
                          @input="calculoMesVol(row.id)"
                        />
                      </td>
                      <td>
                        <input
                          :disabled="estado == 2"
                          :id="'c' + idSitioActual + '-volDiaE-' + row.id"
                          type="number"
                          v-model="
                            empresasData[idSitioActual + '-volDiaE-' + row.id]
                          "
                          class="form-control form-control-sm"
                          step="any"
                          @input="calculoDiaVol(row.id)"
                        />
                      </td>
                      <td>
                        <input
                          :disabled="estado == 2"
                          :id="'c' + idSitioActual + '-carMesE-' + row.id"
                          type="number"
                          v-model="
                            empresasData[idSitioActual + '-carMesE-' + row.id]
                          "
                          class="form-control form-control-sm"
                          step="any"
                          @input="calculoMesCargues(row.id)"
                        />
                      </td>
                      <td>
                        <input
                          :disabled="estado == 2"
                          :id="'c' + idSitioActual + '-carDiaE-' + row.id"
                          type="number"
                          v-model="
                            empresasData[idSitioActual + '-carDiaE-' + row.id]
                          "
                          class="form-control form-control-sm"
                          step="any"
                          @input="calculoDiaCargues(row.id)"
                        />
                      </td>
                      <td>
                        <input
                          :disabled="estado == 2"
                          :id="'c' + idSitioActual + '-adcMesE-' + row.id"
                          type="number"
                          v-model="
                            empresasData[idSitioActual + '-adcMesE-' + row.id]
                          "
                          class="form-control form-control-sm"
                          step="any"
                          @input="calculoMesAdd(row.id)"
                        />
                      </td>
                      <td>
                        <input
                          :id="'c' + idSitioActual + '-adcDiaE-' + row.id"
                          type="number"
                          v-model="
                            empresasData[idSitioActual + '-adcDiaE-' + row.id]
                          "
                          class="form-control form-control-sm"
                          step="any"
                          @input="calculoDiaAdd(row.id)"
                        />
                      </td>
                      <td>
                        <input
                          :disabled="estado == 2"
                          :id="'c' + idSitioActual + '-disMesE-' + row.id"
                          type="number"
                          v-model="
                            empresasData[idSitioActual + '-disMesE-' + row.id]
                          "
                          class="form-control form-control-sm"
                          step="any"
                          @input="calculoMesDescuento(row.id)"
                        />
                      </td>
                      <td>
                        <input
                          :disabled="estado == 2"
                          :id="'c' + idSitioActual + '-disDiaE-' + row.id"
                          type="number"
                          v-model="
                            empresasData[idSitioActual + '-disDiaE-' + row.id]
                          "
                          class="form-control form-control-sm"
                          step="any"
                          @input="calculoDiaDescuento(row.id)"
                        />
                      </td>
                      <td>
                        <input
                          :disabled="estado == 2"
                          type="checkbox"
                          v-model="
                            empresasData[idSitioActual + '-disNO-' + row.id]
                          "
                          :id="'c' + idSitioActual + '-disNO-' + row.id"
                        />
                      </td>
                      <td>
                        <button
                          :disabled="estado == 2"
                          type="button"
                          class="btn btn-sm btn-success"
                          @click="SaveItem(row.id)"
                          v-if="
                            $store.getters.can(
                              'hidrocarburos.nominaciones.edit'
                            )
                          "
                        >
                          <i class="fas fa-save"></i>
                        </button>
                      </td>
                      <td>
                        <button
                          :disabled="estado == 2"
                          type="button"
                          class="btn btn-sm btn-danger"
                          @click="deleteItem(row.id)"
                        >
                          <i class="fas fa-trash-alt"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapState } from "vuex";
import vSelect from "vue-select";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
import { VBPopover } from "bootstrap-vue";

export default {
  name: "NominacionEdit",
  directives: {
    "b-popover": VBPopover,
  },
  components: {
    vSelect,
    Loading,
  },
  data() {
    return {
      descuentoDiaModal: null,
      adicionalDiaModal: null,
      cambioDiaModal: null,
      flagAlert: false,
      cargando: false,
      fecha_inicial: "",
      fecha_final: "",
      nombre: "",
      ciudad: "",
      estado: 1,
      tipo_producto: "",
      observacion: "",
      bloqueSitiosArbol: [],
      filter: "",
      flagArbol: false,
      idNominacion: "",
      idDetNominacion: "",
      volumenes: {},
      cargues: {},
      stateItem: {},
      idSitioActual: "",
      nomSitioActual: "",
      promedioCargueActual: "",
      canEdit: {},
      empresaCheck: {},
      file: "",
      logFile: "",
      modalV: "",
      modalFormItems: "",
      tipo: "",
      modal: {
        title: "",
        accion: "",
      },
      arrListasForm: {
        estados: [],
        empresas: [],
        totEmpresas: [],
      },
      objLista: {},
      empresa: [],
      empresaSelected: {},
      empresasData: {},
      planeacion: "",
      planeacionesOption: [],
      calificacion: "",
      calificacionesOption: [],
      tipos_productoOption: [],
      carguesTotalesEmpresas: [],
      detalles: [],
      buscarDet: "",
      diasNomi: "",
      editedItem: null,
      correoEnviado: 0,
      carguesDia: [],
      carguesMes: [],
      volumenDia: [],
      volumenMes: [],
      valCantVehi: [],
    };
  },
  async beforeMount() {
    await this.loadInfo();
    await this.actGetListas(28);
  },
  validations: {
    fecha_inicial: {
      required,
      minValue: (value) => value > new Date().toISOString(),
    },
    fecha_final: {
      required,
      minValue: (value) => value > new Date().toISOString(),
    },
    nombre: {
      required,
    },
  },
  computed: {
    ...mapState("modOperacion", ["listas"]),
    ...mapState("modHidrocarburos", [
      "bloqueSitios",
      "loading",
      "empresas",
      "nominacion",
    ]),

    filteredRows() {
      return this.bloqueSitios.filter((row) => {
        const nombre = row.nombre.toLowerCase();
        const searchTerm = this.filter.toLowerCase();

        return nombre.includes(searchTerm);
      });
    },

    filteredRowsDetail() {
      return this.detalles.filter((det) => {
        const sitio = det.det_nominacion.sitio.nombre.toLowerCase();
        const empresa = det.empresa.razon_social.toLowerCase();
        const searchTerm = this.buscarDet.toLowerCase();
        return sitio.includes(searchTerm) || empresa.includes(searchTerm);
      });
    },
  },
  methods: {
    ...mapActions("modOperacion", ["actGetListas"]),
    ...mapActions("modHidrocarburos", [
      "actSaveNominacion",
      "actGetBloqueSitios",
      "actSaveNominacionDet",
      "actGetEmpresasNom",
      "actSaveDetNomEmpresa",
      "actUploadFile",
      "actEditNominacion",
      "actDownloadFile",
      "actGetNominacion",
      "actDeleteDetNomEmpresa",
    ]),

    async getPlaneaciones(planeacion) {
      await axios
        .get("api/hidrocarburos/planeaciones/list")
        .then(async (response) => {
          this.planeacionesOption = response.data;
          let plan = await this.planeacionesOption.find(
            (n) => n.id === planeacion
          );
          this.planeacion = plan;
        });
    },

    getCalificaciones(calificacion) {
      axios.get("api/hidrocarburos/calificaciones/lista").then((response) => {
        this.calificacionesOption = response.data;
        let cal = this.calificacionesOption.find((n) => n.id === calificacion);
        this.calificacion = cal;
      });
    },

    async getTiposProducto(tipo_producto) {
      await axios.get("/api/lista/83").then(async (response) => {
        this.tipos_productoOption = response.data;
        let tp = await this.tipos_productoOption.find(
          (n) => n.numeracion === tipo_producto
        ).numeracion;
        this.tipo_producto = tp;
      });
    },
    editData(item) {
      this.editedItem = item;
    },

    saveEditData(item) {
      item.tot_cargues = parseFloat(item.tot_cargues_dia) * this.diasNomi;
      axios({
        method: "PUT",
        url: "/api/hidrocarburos/nominacion/editCargues",
        data: item,
      })
        .then(() => {
          this.editedItem = null;
          this.$swal({
            icon: "success",
            title: "El cargue fue editado exitosamente...",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch((e) => {
          let res = Object.assign({}, e);
          this.$swal({
            icon: "error",
            title:
              "Ocurrio un error, vuelva a intentarlo..." +
              res.response.data.message,
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    highlightMatches(texto) {
      var text = String(texto);
      const matchExists = text
        .toLowerCase()
        .includes(this.filter.toLowerCase());
      if (!matchExists) return text;

      const re = new RegExp(this.filter, "ig");
      return (
        text.replace(re, (matchedText) => `<strong>${matchedText}</strong>`) +
        '<i class="right fas fa-angle-left"></i>'
      );
    },

    saveNominacion() {
      let data = [];
      data = {
        fecha_inicial: this.fecha_inicial,
        fecha_final: this.fecha_final,
        nombre: this.nombre,
        observacion: this.observacion,
        estado: this.estado,
        planeacion_id: this.planeacion.id,
        calificacion_id: this.calificacion.id,
        tipo_producto: this.tipo_producto,
      };
      this.actSaveNominacion(data).then((response) => {
        if (response.id) {
          this.idNominacion = response.id;
          this.flagArbol = true;
          this.$swal({
            icon: "success",
            title: "La nominación base se creó con exito",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        }
      });
    },

    correoEmpresa(tots) {
      var m = this;
      axios
        .get("/api/hidrocarburos/nominacion2/enviarCorreo", {
          params: {
            nominacion_id: m.$route.params.id,
            empresa_id: tots.empresa_id,
          },
        })
        .then(() => {
          m.$swal({
            icon: "success",
            title: "Correo enviado satisfactoriamente",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          m.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    guardaVolumenes(sitio) {
      this.valCantidadVh();
      this.cargando = true;
      let idSitio = sitio.id;
      let data = [];
      let volMes = "";
      let volDia = "";
      let cargueMes = "";
      let cargueDia = "";
      data[0] = this.$route.params.id;

      /* this.getEmpresasOfertas(
        this.planeacion.id,
        idSitio,
        this.arrListasForm.totEmpresas
      ); */

      for (let key in this.volumenes) {
        let info = key.split("-");
        if (info[0] == "mes" && info[1] == idSitio) {
          volMes = this.volumenes[key];
        }
        if (info[0] == "dia" && info[1] == idSitio) {
          volDia = this.volumenes[key];
        }
      }

      for (let key in this.cargues) {
        let info = key.split("-");
        if (info[0] == "mes" && info[1] == idSitio) {
          cargueMes = this.cargues[key];
        }
        if (info[0] == "dia" && info[1] == idSitio) {
          cargueDia = this.cargues[key];
        }
      }

      data[1] = {
        sitio_id: idSitio,
        volumen_mes: volMes,
        volumen_dia: volDia,
        cargues_mes: cargueMes,
        cargues_dia: cargueDia,
      };

      this.actSaveNominacionDet(data).then((response) => {
        if (response.id) {
          this.idDetNominacion = response.id;

          this.$swal({
            icon: "success",
            title: "El volumen se guardo con exito",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
          this.empresa = [];
          this.idSitioActual = idSitio;
          this.nomSitioActual = sitio.nombre;
          this.promedioCargueActual =
            this.tipo_producto == 1
              ? sitio.promedio_cargue
              : sitio.prom_cargue_planta;
          this.cargando = false;
        }
      });
    },

    /* getEmpresasOfertas(planeacion_id, sitio_id, empresas) {
      const array_empresas = this.getArrayEmpresas(empresas);
      let params = {
        planeacion_id,
        sitio_id,
        empresas: array_empresas,
      };
      axios
        .get("/api/hidrocarburos/nominacion/ofertasEmpresas", {
          params,
        })
        .then((response) => {
          let ofertasEmpresas = response.data;
          let array = [];
          ofertasEmpresas.forEach((oferta) => {
            let search = this.arrListasForm.totEmpresas.find(
              (empresa) => empresa.id === oferta.empresa_id
            );
            if (search !== undefined) {
              search.oferta = oferta.cargues_dia_ofertado;
              array.push(search);
            }
          });
          this.arrListasForm.empresas = array;
        });
    }, */

    getArrayEmpresas(empresas) {
      let array = [];
      empresas.forEach((empresa) => {
        array.push(empresa.id);
      });
      return array;
    },

    addEmpresa(type) {
      if (type == "all") {
        for (let i = 0; i < this.arrListasForm.empresas.length; i++) {
          if (
            this.empresaSelected["sitio-" + this.idSitioActual].findIndex(
              (x) => x.id === this.arrListasForm.empresas[i].id
            ) === -1
          ) {
            if (this.arrListasForm.empresas[i].calificacion !== null) {
              this.empresa.push(this.arrListasForm.empresas[i]);
            } else {
              this.$swal({
                icon: "error",
                title:
                  "No puede generar volúmenes por que la empresa " +
                  this.arrListasForm.empresas[i].razon_social +
                  " no tiene calificacion",
                toast: true,
                position: "bottom-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
            }
          }
        }
      }
      this.empresaSelected["sitio-" + this.idSitioActual] = this.empresa; //Agrega empresa a la tabla
    },

    async asignarVolumenes() {
      this.cargando = true;
      let volumenMesSitio = this.volumenes["mes-" + this.idSitioActual];

      let empresasSelect = this.empresaSelected[
        "sitio-" + this.idSitioActual
      ].sort(function(a, b) {
        if (a.calificacion < b.calificacion) {
          return 1;
        }
        if (a.calificacion > b.calificacion) {
          return -1;
        }
        return 0;
      });

      let sumCalif = 0;
      for (let i of empresasSelect) sumCalif += parseFloat(i.calificacion);

      for (let j = 0; j < empresasSelect.length; j++) {
        if (empresasSelect[j].calificacion !== null) {
          let porcentajeCalif = empresasSelect[j].calificacion / sumCalif;
          let volSugeridoMes = volumenMesSitio * porcentajeCalif;
          let carguesSugeridoMes = volSugeridoMes / this.promedioCargueActual;

          //if (empresasSelect[j].oferta >= carguesSugeridoMes / this.diasNomi) {

          this.empresasData[
            this.idSitioActual + "-volMesE-" + empresasSelect[j].id
          ] = volSugeridoMes;

          this.empresasData[
            this.idSitioActual + "-volDiaE-" + empresasSelect[j].id
          ] = volSugeridoMes / this.diasNomi;

          this.empresasData[
            this.idSitioActual + "-carMesE-" + empresasSelect[j].id
          ] = carguesSugeridoMes;

          this.empresasData[
            this.idSitioActual + "-carDiaE-" + empresasSelect[j].id
          ] = carguesSugeridoMes / this.diasNomi;

          this.empresasData[
            this.idSitioActual + "-calificacion-" + empresasSelect[j].id
          ] = empresasSelect[j].calificacion;
          /* } else {
            this.$swal({
              icon: "error",
              title:
                "No puede generar volúmenes por que la empresa " +
                empresasSelect[j].razon_social +
                " no ofertó la cantidad de cargues necesarios",
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          } */
        } else {
          this.$swal({
            icon: "error",
            title:
              "No puede generar volúmenes por que la empresa " +
              empresasSelect[j].razon_social +
              " no tiene calificacion",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
      let empresaData = this.empresasData;
      this.empresasData = empresaData;
      this.cargando = false;
    },

    SaveItem(idEmpresa) {
      //alert(idEmpresa);
      let data = [];
      let volMesE = "";
      let volDiaE = "";
      let carMesE = "";
      let carDiaE = "";
      let adcMesE = "";
      let adcDiaE = "";
      let disMesE = "";
      let disDiaE = "";
      let disNO = "";
      let calificacionE = "";

      for (let key in this.empresasData) {
        let info = key.split("-");
        let empresaData = this.empresasData[key];
        if (
          info[0] == this.idSitioActual &&
          info[1] == "volMesE" &&
          info[2] == idEmpresa
        ) {
          volMesE = empresaData;
        }
        if (
          info[0] == this.idSitioActual &&
          info[1] == "volDiaE" &&
          info[2] == idEmpresa
        ) {
          volDiaE = empresaData;
        }
        if (
          info[0] == this.idSitioActual &&
          info[1] == "carMesE" &&
          info[2] == idEmpresa
        ) {
          carMesE = empresaData;
        }
        if (
          info[0] == this.idSitioActual &&
          info[1] == "carDiaE" &&
          info[2] == idEmpresa
        ) {
          carDiaE = empresaData;
        }
        if (
          info[0] == this.idSitioActual &&
          info[1] == "adcMesE" &&
          info[2] == idEmpresa
        ) {
          adcMesE = empresaData;
        }
        if (
          info[0] == this.idSitioActual &&
          info[1] == "adcDiaE" &&
          info[2] == idEmpresa
        ) {
          adcDiaE = empresaData;
        }
        if (
          info[0] == this.idSitioActual &&
          info[1] == "disMesE" &&
          info[2] == idEmpresa
        ) {
          disMesE = empresaData;
        }
        if (
          info[0] == this.idSitioActual &&
          info[1] == "disDiaE" &&
          info[2] == idEmpresa
        ) {
          disDiaE = empresaData;
        }
        if (
          info[0] == this.idSitioActual &&
          info[1] == "disNO" &&
          info[2] == idEmpresa
        ) {
          disNO = empresaData;
        }
        if (
          info[0] == this.idSitioActual &&
          info[1] == "calificacion" &&
          info[2] == idEmpresa
        ) {
          calificacionE = empresaData;
        }
      }

      if (
        volMesE &&
        volDiaE &&
        carMesE &&
        carDiaE &&
        adcMesE &&
        adcDiaE &&
        disMesE &&
        disDiaE
      ) {
        data[0] = this.idDetNominacion;
        data[1] = idEmpresa;
        data[2] = {
          volumen_mes: volMesE,
          volumen_dia: volDiaE,
          cargues_mes: carMesE,
          cargues_dia: carDiaE,
          adicional_dia: adcDiaE,
          adicional_mes: adcMesE,
          disminucion_dia: disDiaE,
          disminucion_mes: disMesE,
          calificacion: calificacionE,
          compromiso_no_dism: disNO,
        };
        this.actSaveDetNomEmpresa(data).then((response) => {
          if (response.msj) {
            this.$swal({
              icon: "error",
              title: response.msj,
              text:
                "Para realizar este cargue se debe modificar el valor limite presupuestado en el contrato",
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 7000,
              timerProgressBar: true,
            });
          } else {
            this.$swal({
              icon: "success",
              title: "La información de la empresa se guardo con exito",
              toast: true,
              position: "bottom-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
          this.valCantidadVh();
        });
      } else {
        this.stateItem[idEmpresa] = false;
        this.$swal({
          icon: "error",
          title: "Debe ingresar todos los campos de la emrpesa",
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },

    async fileUpload() {
      var formData = new FormData();
      const archivo = document.querySelector("#fileDocu");
      formData.append("file", archivo.files[0]);
      formData.append("nominacion_id", this.$route.params.id);
      formData.append("dias_nominacion", this.diasNomi);

      let response = await this.actUploadFile(formData);
      this.loadInfo();

      if (Object.keys(response).length > 0) {
        this.logFile = response;
      } else {
        this.logFile = "El proceso finalizó con éxito.";
      }
    },

    closeNominacion() {
      if (this.estado == 2) {
        if (this.valCantVehi.length == 0) {
          this.$swal({
            title: "Esta seguro de poner en estado definitivo la Nominación?",
            text: "No podra volver a editar la Nominación",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si, Definitivo",
          }).then((result) => {
            // <--
            if (result.value) {
              var data = [];
              data[0] = this.$route.params.id;
              data[1] = {
                estado: this.estado,
              };

              this.actEditNominacion(data).then(() => {
                this.$swal({
                  icon: "success",
                  title: "La Nominacón se cerró con exito",
                  toast: true,
                  position: "bottom-end",
                  showConfirmButton: false,
                  timer: 2000,
                  timerProgressBar: true,
                });
              });
              this.$router.push({ path: "/Hidrocarburos/Nominaciones" });
            }
          });
        } else {
          this.estado = 1;
          this.$swal({
            icon: "error",
            title: "No puede finalizar la nominacion",
            text:
              "Los cargues diarios deben ser iguales a la sumatoria de vehiculos asignados a las empresas en cada sitio",
          });
        }
      }
    },

    calculoMesCargues(id) {
      let valor = parseFloat(
        this.empresasData[this.idSitioActual + "-carMesE-" + id] / this.diasNomi
      );
      this.empresasData[this.idSitioActual + "-carDiaE-" + id] = valor;

      document.querySelector(
        "#c" + this.idSitioActual + "-carDiaE-" + id
      ).value = valor;
    },

    calculoDiaCargues(id) {
      let valor = parseFloat(
        this.empresasData[this.idSitioActual + "-carDiaE-" + id] * this.diasNomi
      );
      this.empresasData[this.idSitioActual + "-carMesE-" + id] = valor;

      document.querySelector(
        "#c" + this.idSitioActual + "-carMesE-" + id
      ).value = valor;
    },

    calculoMesVol(id) {
      let valor = parseFloat(
        this.empresasData[this.idSitioActual + "-volMesE-" + id] / this.diasNomi
      );
      this.empresasData[this.idSitioActual + "-volDiaE-" + id] = valor;

      document.querySelector(
        "#c" + this.idSitioActual + "-volDiaE-" + id
      ).value = valor;
    },

    calculoDiaVol(id) {
      let valor = parseFloat(
        this.empresasData[this.idSitioActual + "-volDiaE-" + id] * this.diasNomi
      );
      this.empresasData[this.idSitioActual + "-volMesE-" + id] = valor;

      document.querySelector(
        "#c" + this.idSitioActual + "-volMesE-" + id
      ).value = valor;
    },

    calculoMesAdd(id) {
      let valor = parseFloat(
        this.empresasData[this.idSitioActual + "-adcMesE-" + id] / this.diasNomi
      );
      this.empresasData[this.idSitioActual + "-adcDiaE-" + id] = valor;

      document.querySelector(
        "#c" + this.idSitioActual + "-adcDiaE-" + id
      ).value = valor;
    },

    calculoDiaAdd(id) {
      let valor = parseFloat(
        this.empresasData[this.idSitioActual + "-adcDiaE-" + id] * this.diasNomi
      );
      this.empresasData[this.idSitioActual + "-adcMesE-" + id] = valor;

      document.querySelector(
        "#c" + this.idSitioActual + "-adcMesE-" + id
      ).value = valor;
    },

    calculoDiaDescuento(id) {
      let valor = parseFloat(
        this.empresasData[this.idSitioActual + "-disDiaE-" + id] * this.diasNomi
      );
      this.empresasData[this.idSitioActual + "-disMesE-" + id] = valor;
      document.querySelector(
        "#c" + this.idSitioActual + "-disMesE-" + id
      ).value = valor;
    },

    calculoMesDescuento(id) {
      let valor = parseFloat(
        this.empresasData[this.idSitioActual + "-disMesE-" + id] / this.diasNomi
      );
      this.empresasData[this.idSitioActual + "-disDiaE-" + id] = valor;

      document.querySelector(
        "#c" + this.idSitioActual + "-disDiaE-" + id
      ).value = valor;
    },

    cambiarAdicionalesDia() {
      this.empresaSelected["sitio-" + this.idSitioActual].forEach((row) => {
        let valor = parseFloat(this.adicionalDiaModal);
        let valor2 = valor * this.diasNomi;
        this.empresasData[this.idSitioActual + "-adcDiaE-" + row.id] = valor;
        this.empresasData[this.idSitioActual + "-adcMesE-" + row.id] = valor2;
        document.querySelector(
          "#c" + this.idSitioActual + "-adcDiaE-" + row.id
        ).value = valor;
        document.querySelector(
          "#c" + this.idSitioActual + "-adcMesE-" + row.id
        ).value = valor2;
      });
    },

    cambiarDescuentosDia() {
      this.empresaSelected["sitio-" + this.idSitioActual].forEach((row) => {
        this.empresasData[
          this.idSitioActual + "-disDiaE-" + row.id
        ] = parseFloat(this.descuentoDiaModal);
        this.empresasData[this.idSitioActual + "-disMesE-" + row.id] =
          parseFloat(this.descuentoDiaModal) * this.diasNomi;
      });
    },

    guardadoGeneral() {
      let arregloTotal = [];
      this.empresaSelected["sitio-" + this.idSitioActual].forEach((row) => {
        let data = [];
        let volMesE = "";
        let volDiaE = "";
        let carMesE = "";
        let carDiaE = "";
        let adcMesE = "";
        let adcDiaE = "";
        let disMesE = "";
        let disDiaE = "";
        let disNO = "";
        let calificacionE = "";

        for (let key in this.empresasData) {
          let empresaData = this.empresasData[key];
          let info = key.split("-");

          if (
            info[0] == this.idSitioActual &&
            info[1] == "volMesE" &&
            info[2] == row.id
          ) {
            volMesE = empresaData;
          }
          if (
            info[0] == this.idSitioActual &&
            info[1] == "volDiaE" &&
            info[2] == row.id
          ) {
            volDiaE = empresaData;
          }
          if (
            info[0] == this.idSitioActual &&
            info[1] == "carMesE" &&
            info[2] == row.id
          ) {
            carMesE = empresaData;
          }
          if (
            info[0] == this.idSitioActual &&
            info[1] == "carDiaE" &&
            info[2] == row.id
          ) {
            carDiaE = empresaData;
          }
          if (
            info[0] == this.idSitioActual &&
            info[1] == "adcMesE" &&
            info[2] == row.id
          ) {
            adcMesE = empresaData;
          }
          if (
            info[0] == this.idSitioActual &&
            info[1] == "adcDiaE" &&
            info[2] == row.id
          ) {
            adcDiaE = empresaData;
          }
          if (
            info[0] == this.idSitioActual &&
            info[1] == "disMesE" &&
            info[2] == row.id
          ) {
            disMesE = empresaData;
          }
          if (
            info[0] == this.idSitioActual &&
            info[1] == "disDiaE" &&
            info[2] == row.id
          ) {
            disDiaE = empresaData;
          }
          if (
            info[0] == this.idSitioActual &&
            info[1] == "disNO" &&
            info[2] == row.id
          ) {
            disNO = empresaData;
          }
          if (
            info[0] == this.idSitioActual &&
            info[1] == "calificacion" &&
            info[2] == row.id
          ) {
            calificacionE = empresaData;
          }
        }

        if (
          volMesE &&
          volDiaE &&
          carMesE &&
          carDiaE &&
          adcMesE &&
          adcDiaE &&
          disMesE &&
          disDiaE
        ) {
          data[0] = this.idDetNominacion;
          data[1] = row.id;
          data[2] = {
            volumen_mes: volMesE,
            volumen_dia: volDiaE,
            cargues_mes: carMesE,
            cargues_dia: carDiaE,
            adicional_dia: adcDiaE,
            adicional_mes: adcMesE,
            disminucion_dia: disDiaE,
            disminucion_mes: disMesE,
            calificacion: calificacionE,
            compromiso_no_dism: disNO,
          };
        }
        arregloTotal.push(data);
      });
      //guadado total ok
      axios({
        method: "POST",
        url: "/api/hidrocarburos/nominacion/guardarTotal",
        data: arregloTotal,
      })
        .then(() => {
          this.valCantidadVh();
          this.$swal({
            icon: "success",
            title: "Datos guardados satisfactoriamente",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    enviarCorreo() {
      var m = this;
      axios
        .get("/api/hidrocarburos/nominacion2/enviarCorreo", {
          params: {
            nominacion_id: m.$route.params.id,
          },
        })
        .then((response) => {
          m.$swal({
            icon: "success",
            title: "Correo enviado satisfactoriamente",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        })
        .catch(function(error) {
          m.$swal({
            icon: "error",
            title: "Error, en el envio de correo:" + error,
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    downloadFile() {
      this.actDownloadFile().then((response) => {
        this.file = response;

        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(
          new Blob([this.file], { type: "application/xlsx" })
        );
        link.setAttribute("download", "formatoNominacion.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    },

    deleteItem(idEmpresa) {
      let data = [];
      data[0] = this.idDetNominacion;
      data[1] = idEmpresa;
      this.actDeleteDetNomEmpresa(data);

      var newObject = {};
      var newObjectEmpresa = [];

      this.empresasData[this.idSitioActual + "-volMesE-" + idEmpresa] = 0;
      this.empresasData[this.idSitioActual + "-volDiaE-" + idEmpresa] = 0;
      this.empresasData[this.idSitioActual + "-carMesE-" + idEmpresa] = 0;
      this.empresasData[this.idSitioActual + "-carDiaE-" + idEmpresa] = 0;
      this.empresasData[this.idSitioActual + "-adcMesE-" + idEmpresa] = 0;
      this.empresasData[this.idSitioActual + "-adcDiaE-" + idEmpresa] = 0;
      this.empresasData[this.idSitioActual + "-disMesE-" + idEmpresa] = 0;
      this.empresasData[this.idSitioActual + "-disDiaE-" + idEmpresa] = 0;
      this.empresasData[this.idSitioActual + "-disNO-" + idEmpresa] = false;

      for (let key in this.empresaSelected["sitio-" + this.idSitioActual]) {
        if (
          this.empresaSelected["sitio-" + this.idSitioActual][key].id !=
          idEmpresa
        ) {
          newObjectEmpresa.push(
            this.empresaSelected["sitio-" + this.idSitioActual][key]
          );
        }
      }

      for (let key in this.empresaSelected) {
        if (key != "sitio-" + this.idSitioActual) {
          newObject[key] = this.empresaSelected[key];
        }
      }
      newObject["sitio-" + this.idSitioActual] = newObjectEmpresa;
      this.empresaSelected = newObject;
      this.valCantidadVh();
    },

    async loadInfo() {
      this.cargando = true;
      await this.actGetBloqueSitios().then(async () => {
        await this.bloqueSitios.forEach(async (bloque) => {
          await bloque.sitios.forEach((sitio) => {
            this.empresaSelected["sitio-" + sitio.id] = [];
          });
        });

        await this.actGetNominacion(this.$route.params.id).then(async () => {
          let datacalfemp = {
            calificacion: this.nominacion.calificacion_id,
          };
          this.getCalificaciones(this.nominacion.calificacion_id);
          this.getPlaneaciones(this.nominacion.planeacion_id);
          this.getTiposProducto(this.nominacion.tipo_producto);
          await this.actGetEmpresasNom(datacalfemp).then(async () => {
            await this.buildArrayEmpresasList(this.empresas);
            this.fecha_inicial = this.nominacion.fecha_inicial;
            this.fecha_final = this.nominacion.fecha_final;
            this.nombre = this.nominacion.nombre;
            this.observacion = this.nominacion.observacion;
            this.diasNomi = this.nominacion.dias_nomi;
            this.estado = this.nominacion.estado;
            await this.nominacion.det_nominaciones.forEach(async (det) => {
              this.volumenes["mes-" + det.sitio_id] = det.volumen_mes;
              this.volumenes["dia-" + det.sitio_id] = det.volumen_dia;
              this.cargues["mes-" + det.sitio_id] = det.cargues_mes;
              this.cargues["dia-" + det.sitio_id] = det.cargues_dia;

              await det.det_nominacion_empresas.forEach((detEmpresa) => {
                for (let i = 0; i < this.arrListasForm.empresas.length; i++) {
                  if (
                    this.arrListasForm.empresas[i].id == detEmpresa.empresa_id
                  ) {
                    this.empresaSelected["sitio-" + det.sitio_id].push(
                      this.arrListasForm.empresas[i]
                    ); //Agrega empresa a la tabla
                  }
                }
                this.arrListasForm.totEmpresas = this.arrListasForm.empresas;
                //Inicializa los valores promediados para ingresar las empresas.

                this.empresasData[
                  det.sitio_id + "-calificacion-" + detEmpresa.empresa_id
                ] = detEmpresa.calificacion;

                this.empresasData[
                  det.sitio_id + "-volMesE-" + detEmpresa.empresa_id
                ] = detEmpresa.volumen_mes;
                this.empresasData[
                  det.sitio_id + "-volDiaE-" + detEmpresa.empresa_id
                ] = detEmpresa.volumen_dia;
                this.empresasData[
                  det.sitio_id + "-carMesE-" + detEmpresa.empresa_id
                ] = detEmpresa.cargues_mes;
                this.empresasData[
                  det.sitio_id + "-carDiaE-" + detEmpresa.empresa_id
                ] = detEmpresa.cargues_dia;
                this.empresasData[
                  det.sitio_id + "-adcMesE-" + detEmpresa.empresa_id
                ] = detEmpresa.adicional_mes;
                this.empresasData[
                  det.sitio_id + "-adcDiaE-" + detEmpresa.empresa_id
                ] = detEmpresa.adicional_dia;
                this.empresasData[
                  det.sitio_id + "-disMesE-" + detEmpresa.empresa_id
                ] = detEmpresa.disminucion_mes;
                this.empresasData[
                  det.sitio_id + "-disDiaE-" + detEmpresa.empresa_id
                ] = detEmpresa.disminucion_dia;
                this.empresasData[
                  det.sitio_id + "-disNO-" + detEmpresa.empresa_id
                ] = detEmpresa.compromiso_no_dism;
              });
            });
          });

          await this.getGeneralTot();
          await this.calcularVolumenCargueTotal();
          await this.valCantidadVh();
        });
      });

      this.cargando = false;
    },

    buildArrayEmpresasList(empresasCalificacion) {
      //aca
      let empresas = [];
      for (let i = 0; i < empresasCalificacion.length; i++) {
        let res_calif = empresasCalificacion[i].resultado_general;
        empresas.push({
          id: empresasCalificacion[i].empresa_id,
          razon_social: empresasCalificacion[i].empresa.razon_social,
          calificacion: res_calif,
        });
      }
      this.arrListasForm.empresas = empresas;
    },

    calcVolumenes(opcion, idSitio, promedioCargue) {
      if (promedioCargue == 0) {
        promedioCargue = 200;
      }
      if (opcion == 1) {
        let vol_mes = this.volumenes["mes-" + idSitio];

        document.getElementById("volumenes-dia-" + idSitio).value = (
          vol_mes / this.diasNomi
        ).toFixed(2);
        this.volumenes["dia-" + idSitio] = (vol_mes / this.diasNomi).toFixed(2);

        document.getElementById("cargues-mes-" + idSitio).value = (
          vol_mes / promedioCargue
        ).toFixed(2);
        this.cargues["mes-" + idSitio] = (vol_mes / promedioCargue).toFixed(2);

        document.getElementById("cargues-dia-" + idSitio).value = (
          this.cargues["mes-" + idSitio] / this.diasNomi
        ).toFixed(2);
        this.cargues["dia-" + idSitio] = (
          this.cargues["mes-" + idSitio] / this.diasNomi
        ).toFixed(2);
      }

      if (opcion == 2) {
        let vol_dia = this.volumenes["dia-" + idSitio];

        document.getElementById("volumenes-mes-" + idSitio).value = (
          vol_dia * this.diasNomi
        ).toFixed(2);
        this.volumenes["mes-" + idSitio] = (vol_dia * this.diasNomi).toFixed(2);

        document.getElementById("cargues-mes-" + idSitio).value = (
          (vol_dia * this.diasNomi) /
          promedioCargue
        ).toFixed(2);
        this.cargues["mes-" + idSitio] = (
          (vol_dia * this.diasNomi) /
          promedioCargue
        ).toFixed(2);

        document.getElementById("cargues-dia-" + idSitio).value = (
          this.cargues["mes-" + idSitio] / this.diasNomi
        ).toFixed(2);
        this.cargues["dia-" + idSitio] = (
          this.cargues["mes-" + idSitio] / this.diasNomi
        ).toFixed(2);
      }

      if (opcion == 3) {
        let cargues_mes = this.cargues["mes-" + idSitio];

        document.getElementById("volumenes-mes-" + idSitio).value = (
          cargues_mes * promedioCargue
        ).toFixed(2);
        this.volumenes["mes-" + idSitio] = (
          cargues_mes * promedioCargue
        ).toFixed(2);

        document.getElementById("volumenes-dia-" + idSitio).value = (
          (cargues_mes * promedioCargue) /
          this.diasNomi
        ).toFixed(2);
        this.volumenes["dia-" + idSitio] = (
          (cargues_mes * promedioCargue) /
          this.diasNomi
        ).toFixed(2);

        document.getElementById("cargues-dia-" + idSitio).value = (
          this.cargues["mes-" + idSitio] / this.diasNomi
        ).toFixed(2);
        this.cargues["dia-" + idSitio] = (
          this.cargues["mes-" + idSitio] / this.diasNomi
        ).toFixed(2);
      }
      if (opcion == 4) {
        let cargues_dia = this.cargues["dia-" + idSitio];

        document.getElementById("volumenes-mes-" + idSitio).value = (
          cargues_dia *
          promedioCargue *
          this.diasNomi
        ).toFixed(2);
        this.volumenes["mes-" + idSitio] = (
          cargues_dia *
          promedioCargue *
          this.diasNomi
        ).toFixed(2);

        document.getElementById("volumenes-dia-" + idSitio).value = (
          cargues_dia * promedioCargue
        ).toFixed(2);
        this.volumenes["dia-" + idSitio] = (
          cargues_dia * promedioCargue
        ).toFixed(2);

        document.getElementById("cargues-mes-" + idSitio).value = (
          this.cargues["dia-" + idSitio] * this.diasNomi
        ).toFixed(2);
        this.cargues["mes-" + idSitio] = (
          this.cargues["dia-" + idSitio] * this.diasNomi
        ).toFixed(2);
      }
    },

    calcTotalesGeneral() {
      axios
        .post("api/hidrocarburos/nominacion/calcTotales", {
          planeacion_id: this.planeacion.id,
          nominacion_id: this.$route.params.id,
          calificacion_id: this.calificacion.id,
          dias_nominacion: this.diasNomi,
        })
        .then(() => {
          this.getGeneralTot();
          this.$swal({
            icon: "success",
            title: "Se calcularon los valores con exito",
            toast: true,
            position: "bottom-end",
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
          });
        });
    },

    async getGeneralTot() {
      axios
        .get("api/hidrocarburos/nominacion/getTotalesGeneral", {
          params: {
            nominacion_id: this.$route.params.id,
          },
        })
        .then((response) => {
          this.carguesTotalesEmpresas = response.data;
        });
    },

    getDetalles() {
      axios
        .get("api/hidrocarburos/nominacion/getDetalles", {
          params: {
            nominacion_id: this.$route.params.id,
          },
        })
        .then((response) => {
          this.detalles = response.data;
        });
    },

    async calcularVolumenCargueTotal() {
      let me = this;
      let volumenMes = [];
      let volumenDia = [];
      let cargueMes = [];
      let cargueDia = [];
      let cargues = me.cargues;
      let volumenes = me.volumenes;
      await me.bloqueSitios.forEach(async (bloques, index) => {
        cargueDia[index] = 0;
        cargueMes[index] = 0;
        volumenMes[index] = 0;
        volumenDia[index] = 0;
        await bloques.sitios.forEach(async (sitio) => {
          let a = "dia-" + sitio.id;
          let b = "mes-" + sitio.id;
          if (cargues[a] !== undefined) {
            cargueDia[index] = cargueDia[index] + parseFloat(cargues[a]);
          }
          if (cargues[b] !== undefined) {
            cargueMes[index] = cargueMes[index] + parseFloat(cargues[b]);
          }
          if (volumenes[a] !== undefined) {
            volumenDia[index] = volumenDia[index] + parseFloat(volumenes[a]);
          }

          if (volumenes[b] !== undefined) {
            volumenMes[index] = volumenMes[index] + parseFloat(volumenes[b]);
          }
        });
      });

      me.carguesDia = cargueDia;
      me.carguesMes = cargueMes;
      me.volumenDia = volumenDia;
      me.volumenMes = volumenMes;
    },

    valCantidadVh() {
      axios
        .get("api/hidrocarburos/nominacion/cantidadesSitios", {
          params: {
            nominacion_id: this.$route.params.id,
          },
        })
        .then((response) => {
          let data = response.data;
          let carguesSit = Object.keys(this.cargues);
          for (let i = 0; i < carguesSit.length; i++) {
            let info = carguesSit[i].split("-");
            let idSitio = info[1];
            if (info[0] == "dia") {
              for (let j = 0; j < data.length; j++) {
                if (data[j].sitio_id == idSitio) {
                  let cargueDia = Math.round(this.cargues[carguesSit[i]]);
                  let totEmp = Math.round(data[j].cargues_diatot);
                  if (cargueDia !== totEmp) {
                    document
                      .querySelector("#cargues-dia-" + idSitio)
                      .classList.add("is-invalid");
                    this.valCantVehi.push({
                      sitio: idSitio,
                      cargueDia: cargueDia,
                      totEmp: totEmp,
                    });
                  }
                }
              }
            }
          }
        });
    },
  },

  async mounted() {},
};
</script>
